// import React, { useState } from 'react';
// import axios from 'axios';
// import {  useNavigate } from 'react-router-dom';
// import './css/register.css'
// import RegisterFormByPatient from './RegisterFormByPatient';


// const RegisterByPatient = () => {
//   const navigate = useNavigate();

//   // orthoPlanId refere-se aos planos escolhidos , segue tabela:
//   //| plano | valor de id 
//   //  NENHUM|      1
//   //  BASICO|      2
//   //  SMART |      3
//   //   VIP  |      4

//   const [patientFormData, setPatientFormData] = useState({
//     name: '',
//     age: '',
//     cpf: '',
//     rg: '',
//     email: '',
//     password:'',
//     phone1: '',
//     phone2: '',
//     planCardCode: '',
//     planName: '',
//     clinic_id: 1,
 
//   });

//   const [addressFormData, setAddressFormData] = useState({
//     postal_code: '',
//     street: '',
//     city: '',
//     country: '',
//     address_number: '',
//   });



//   const handlePatientChange = (e) => {
//     const { name, value } = e.target;
//     setPatientFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleAddressChange = (e) => {
//     const { name, value } = e.target;
//     setAddressFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };



//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
      
//       const response =  await axios.post('http://localhost:3001/patients/createByUser', {
//           createPatientUserDto: {
//             ...patientFormData,
//             ortho:false,
//             vip:false,
//             age:Number(patientFormData.age),
//             orthoPlanId:1,
            
//           },
//           createAddressDto: {...addressFormData,
//             address_number:Number(addressFormData.address_number)
//           },
//         });
//         alert('Seu cadastro foi efetuado com sucesso!');
//        console.log(response.data);
//        const {id} = response.data
//        if (response.data) {
//         const login =  await axios.post('http://localhost:3001/auth/patient', {
//           doc:response.data.email,
//           password:response.data.password
//         });
//         navigate(`/patient-profile/${id}`)
//         console.log(login.data);
//        }
   
    
//       // Limpar os formulários após o cadastro bem-sucedido
//       setPatientFormData({
//         name:'',
//         age: '',
//         cpf: '',
//         rg: '',
//         email: '',
//         password:'',
//         phone1: '',
//         phone2: '',
//         planCardCode: '',
//         planName: '',
       
//       });
//       setAddressFormData({
//         postal_code: '',
//         street: '',
//         city: '',
//         country: '',
//         address_number: '',
//       });

//     } catch (error) {
//       console.error('Erro ao cadastrar paciente:', error);
//       alert('Erro ao cadastrar paciente. Por favor, tente novamente.');
//     }
//   };

//   // const handleClick = () => {
//   //   navigate('/auth-odk-patient-kahjsyehb'); // Redirects to the desired route
//   // };


//   return (
//     <div className='register'>
//       {/* <div className='cadastro-paciente-div'>
//       <span className='cadastro-paciente'>Faça seu cadastro</span>
//       </div> */}
   
//     <RegisterFormByPatient handleSubmit={handleSubmit}
//       patientFormData={patientFormData}
//       addressFormData={addressFormData}
//       handlePatientChange={handlePatientChange}
//       handleAddressChange={handleAddressChange}
//       />
//       {/* <div className='cad-btns-container'>
//         <button className='adm-btns' onClick={handleClick}>Voltar</button>
//       </div> */}
//     </div>
    
//   );
// };

// export default RegisterByPatient;

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './css/register.css'
import RegisterFormByPatient from './RegisterFormByPatient';

const RegisterByPatient = () => {
  const navigate = useNavigate();

  const handleSubmit = async (formValues) => {
    const { name, age, cpf, rg, email, password, phone1, phone2, planCardCode, planName } = formValues;
    
    const patientFormData = {
      name,
      age,
      cpf,
      rg,
      email,
      password,
      phone1,
      phone2,
      planCardCode,
      planName,
      clinic_id: 1,
      ortho: false,
      vip: false,
      orthoPlanId: 1,
    };

    const addressFormData = {
      postal_code: '',
      street: '',
      city: '',
      country: '',
      address_number: 0,
    };

    try {
      const response = await axios.post('http://localhost:3001/patients/createByUser', {
        createPatientUserDto: patientFormData,
        createAddressDto: addressFormData,
      });

      alert('Seu cadastro foi efetuado com sucesso!');
      console.log(response.data);

      const { id } = response.data;

      if (response.data) {
        const login = await axios.post('http://localhost:3001/auth/patient', {
          doc: email,
          password: password
        });

        navigate(`/patient-profile/${id}`);
        console.log(login.data);
      }

    } catch (error) {
      // console.error('Erro ao cadastrar paciente:', error);
      alert(error.response.data.message);
    }
  };

  return (
    <div className='register'>
      <RegisterFormByPatient handleSubmit={handleSubmit} />
    </div>
  );
};

export default RegisterByPatient;
