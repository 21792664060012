// import React from 'react';
// import './css/peopleCardContainerMob.css';
// import './css/peopleCardContainer.css';
// import perfilIcon from '../../../assets/icons/perfilIcone.png'
// import QueeRemoverBtn from '../queeAdm/QueeRemoverBtn';



// function PeopleCardContainer({ peopleList , noRemoverBtn }) {
//   const personName = localStorage.getItem('patientName')
//   return (
//     <div className='people-card-container'>
     
//       {peopleList.length === 0 ? (
//         <p></p>
//       ) : (
//         peopleList.map((person, index) => (
//           <div className='people-card' key={person.id}>
          
//             {/* <p className={`people-card ${index < 3 ? `position-${index + 1}` : ''}`}>{index + 1} {person.name}</p> */}
//             <div className={`people-card ${personName === person.name ? `name-red` : 'person-quee-name'}`}>
//                 <img className="perfil-icon" src={perfilIcon} alt="icon" />
//               <div className='people-place-container'>
//                 <span className='number-quee'>{index + 1}</span>  
//                 <p>{person.name}</p> <QueeRemoverBtn noRemoverBtn={noRemoverBtn} personId={person.id}/>
//               </div>
//             </div>
//             {/* Adicione aqui os outros dados que deseja exibir */}
//           </div>
//         ))
//       )}
//     </div>
//   );
// }

// export default PeopleCardContainer;

import React from 'react';
import './css/peopleCardContainerMob.css';
import './css/peopleCardContainer.css';
import perfilIcon from '../../../assets/icons/perfilIcone.png'
import QueeRemoverBtn from '../queeAdm/QueeRemoverBtn';
import formatName from '../../../utils/formatName';

function PeopleCardContainer({ peopleList, noRemoverBtn }) {
  const personName = localStorage.getItem('patientName');

  // Função para extrair o primeiro nome e o último sobrenome
  // const formatName = (fullName) => {
  //   const nameParts = fullName.split(' ').map(part => 
  //     part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  //   );
  //   if (nameParts.length > 1) {
  //     return `${nameParts[0]} ${nameParts[1]} ${nameParts[nameParts.length - 1]}`;
  //   }
  //   return fullName; // Caso haja apenas um nome, retorna ele mesmo
  // };
  

  return (
    <div className='people-card-container'>
      {peopleList.length === 0 ? (
        <p></p>
      ) : (
        peopleList.map((person, index) => (
          <div className='people-card' key={person.id}>
            <div className={`people-card ${personName === person.name ? `name-red` : 'person-quee-name'}`}>
              <img className="perfil-icon" src={perfilIcon} alt="icon" />
              <div className='people-place-container'>
                <span className='number-quee'>{index + 1}</span>
                <p>{formatName(person.name)}</p>
                <QueeRemoverBtn noRemoverBtn={noRemoverBtn} personId={person.id} />
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default PeopleCardContainer;
