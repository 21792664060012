import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderOrtho from '../../components/header/HeaderOrtho';
import ProductsCard from '../../components/productsCard/ProductsCard';
import axios from 'axios'; // Biblioteca para fazer chamadas HTTP
import ProductsCardCover from '../../components/productsCard/ProductsCardCover';
import './css/patient-profile.css'

const PatientProfile = () => {
  const { id } = useParams(); // Obtém o 'id' dos parâmetros da URL

  // States para armazenar os dados
  const [productsValue, setproductsValue] = useState(null); // Armazena o objeto de produtos
  const [patientInfos, setPatientInfos] = useState(null);
  const [checkoutInfo, setCheckoutInfo] = useState(null); // Armazena o objeto do paciente, sem o campo password
  // Armazena o objeto do paciente, sem o campo password
  // Função para buscar os produtos
  const fetchProducts = async () => {
    try {
      const response = await axios.get('http://localhost:3001/credits/products');
      if (response.data.length > 0) {
        setproductsValue(response.data[0]); // Salva apenas o primeiro objeto do array
      }
    } catch (error) {
      console.error('Erro ao buscar os produtos:', error);
    }
  };

  // Função para buscar os dados do paciente
  const fetchPatient = async () => {
    try {
      const response = await axios.get(`http://localhost:3001/patients/${id}`);
      const patientData = response.data;
      // Remove o campo password do objeto de paciente
      const { password, ...patientWithoutPassword } = patientData;
      setPatientInfos(patientWithoutPassword); // Salva o objeto sem o campo password
    } catch (error) {
      console.error('Erro ao buscar os dados do paciente:', error);
    }
  };

  const handleCheckout = async (productInfo) => {
    console.log(productInfo.quantity);
    
    const data = {
      customer: {
        Name: patientInfos.name,
        email: patientInfos.email,
        tax_id: patientInfos.cpf,
      },
      reference_id: id, // id do paciente
      items: [
        {
          reference_id: productInfo.refValue,
          name: productInfo.refId,
          description: productInfo.description,
          quantity: productInfo.quantity,
          unit_amount: (Number(productsValue[productInfo.refValue])*100)/productInfo.quantity,
        },
      ],
      customer_modifiable: true,
      payment_methods: [{type: 'CREDIT_CARD'}, {type: 'PIX'}],
      payment_methods_configs: [
        {
          type: 'CREDIT_CARD',
          config_options: [
            {
              option: 'INSTALLMENTS_LIMIT',
              value: '12'
            },
            // {
            //   option: 'INTEREST_FREE_INSTALLMENTS',
            //   value: '0'
            // }
          ]
        }
      ],
      notification_urls: ['https://sys-od.up.railway.app/pagseguro/webhook'],
      payment_notification_urls: [
        'https://sys-od.up.railway.app/pagseguro/webhook'
      ]
      // payment_methods_configs: [
      //   {
      //     config_options: [
      //       {
      //         option: 'INSTALLMENTS_LIMIT',
      //         value: String(productInfo.quantity),
      //       },
      //       {
      //         option: 'INTEREST_FREE_INSTALLMENTS',
      //         value: '0'
      //       }
      //     ],
      //     type: 'CREDIT_CARD',
      //   },
      // ],
    };
  
    try {
      const response = await fetch('http://localhost:3001/pagseguro/checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      
      const result = await response.json();
      setCheckoutInfo(result)
      console.log(result);
      console.log(productInfo)
      const checkoutUrl = result.links[1].href
      setTimeout(() => {
        // Redireciona para o checkoutUrl
        // window.location.href = checkoutUrl;
      }, 2000);
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  // useEffect para fazer o fetch quando o componente for montado
  useEffect(() => {
    fetchProducts();
    fetchPatient();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); // Reexecuta o efeito sempre que o 'id' mudar

  return (
    <div>
      <HeaderOrtho />
      <ProductsCardCover/>
      <div>
        <h2>Bem vindo {patientInfos && patientInfos.name} </h2>
      </div>
      {/* Passa o productsInfo como props para o ProductsCard */}
      {productsValue && <ProductsCard productsInfo={productsValue} handleCheckout={handleCheckout} />}
    </div>
  );
};

export default PatientProfile;
