import React from 'react';
import './css/info-values.css';

export default function VipInfoValues({ productName }) {
  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Vip 1' && (
        <ul className='product-itens-list'>
          <li>&bull; 1 crédito para manutenção de aparelho convencional.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP).</li>
          <li>&bull; 1 crédito para limpeza dentária (profilaxia).</li>
          <li>&bull; Reparo vip, reparos no aparelho durante 30 dias após crédito utilizado.</li>
        </ul>
      )}
      {productName === 'Vip 3' && (
        <ul className='product-itens-list'>
          <li>&bull; 3 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 195,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP).</li>
          <li>&bull; 3 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 1 crédito para restauração dentária (obturação).</li>
          <li>&bull; Reparo vip, reparos no aparelho durante 30 dias após crédito utilizado.</li>
        </ul>
      )}
      {productName === 'Vip 6' && (
        <ul className='product-itens-list'>
          <li>&bull; 6 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 190,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP).</li>
          <li>&bull; 6 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 2 créditos para restauração dentária (obturação).</li>
          <li>&bull; Reparo vip, reparos no aparelho durante 30 dias após crédito utilizado.</li>
          <li>&bull; 1 voucher de 30% de desconto para 1 extração.</li>
        </ul>
      )}
      {productName === 'Vip 12' && (
        <ul className='product-itens-list'>
          <li>&bull; 12 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 180,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP).</li>
          <li>&bull; 12 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 3 créditos para restauração dentária (obturação).</li>
          <li>&bull; 1 crédito para tratamento de canal.</li>
          <li>&bull; Reparo vip, reparos no aparelho durante 30 dias após crédito utilizado.</li>
          <li>&bull; 1 kit de clareamento caseiro (plaquinha + gel).</li>
          <li>&bull; 1 kit de higiene bucal para aparelhos.</li>
          <li>&bull; 2 vouchers de 30% de desconto, para 1 extração cada.</li>
        </ul>
      )}
      <button className='button-info-values'>ADQUIRIR!</button>
    </div>
  );
}
