import React from 'react'
import './css/authCover.css'

export default function AuthCover() {
  return (
    <div className='auth-main-ort'>

      <div className='auth-cover-text-box-ort'>
        <span className='bem-vindo-ort'>Bem Vindo!</span>
        <span className='acesse-ort'>Acesse seu Perfil</span>
      </div>
    
    </div>
  )
}
