import React, { useState } from 'react';
import './css/productsCardMob.css';
import StandardInfoValues from './prodctsInfosValues/StandardInfoValues';

export default function StandardContainer({ standard,handleCheckout }) {
  // Estado que controla quais produtos estão expandidos
  const [expandedItems, setExpandedItems] = useState({});

  // Função para alternar o estado de um produto específico
  const toggleExpand = (item) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [item]: !prevState[item] // Alterna o estado de exibição
    }));
  };

  return (
    <div className='products-card-sub-container'>
      <div className='product-infos-container' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>STANDARD ONE</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {standard.standard1},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('standardOne')} style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.standardOne && <StandardInfoValues productName="Standard 1" handleCheckout={handleCheckout} />}
        
      </div>

      <div className='product-infos-container' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>STANDARD TRI</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {standard.standard3},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('standardTri')} style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.standardTri && <StandardInfoValues productName="Standard 3"  handleCheckout={handleCheckout}/>}
      </div>

      <div className='product-infos-container' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>STANDARD SIX</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {standard.standard6},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('standardSix')} style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.standardSix && <StandardInfoValues productName="Standard 6"  handleCheckout={handleCheckout}/>}
      </div>

      <div className='product-infos-container' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span style={{width:'50vw',textAlign:'left'}}>STANDARD DOZEN</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {standard.standard12},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('standardDozen')} style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.standardDozen && <StandardInfoValues productName="Standard 12" handleCheckout={handleCheckout} />}
      </div>
    </div>
  );
}
