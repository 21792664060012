import React from 'react'
import './css/cadastro.css'
import RegisterByPatient from '../../components/cadastro/RegisterByPatient'
import HeaderOrtho from '../../components/header/HeaderOrtho'
import RegisterByPatCover from '../../components/cadastro/RegisterByPatCover'

export default function CadastroPatient() {
  return (
    <div className='main-cad-pat'>
      <HeaderOrtho />
      <RegisterByPatCover/>
      <RegisterByPatient/>
    </div>
  )
}
