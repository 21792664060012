import React, { useState } from 'react';
import './css/explanation-boxes.css';

export default function SmartBoxExp({ toggleExpand }) {
  const [stage, setStage] = useState(1); // Estado para controlar o estágio atual

  // Função para ir ao próximo estágio
  const nextStage = () => {
    if (stage < 3) setStage(stage + 1);
    if (stage === 2) toggleExpand();
  };

  // Função para voltar ao estágio anterior
  const prevStage = () => {
    if (stage > 1) setStage(stage - 1);
    if (stage === 3) toggleExpand();
  };

  // Renderização condicional com base no estágio
  return (
    <div className='box-explanation-main-container'>
      {stage === 1 && (
        <>
          <span className='box-exp-subtitle'>O Seu Melhor Amigo em Cuidados Odontológicos!</span>
          <p className='box-exp-first-paragraf-smart'>
           <span>A escolha perfeita para quem valoriza a saúde bucal e quer o melhor para o seu sorriso.
            </span> 
            <span>Ideal para quem busca um equilíbrio entre qualidade e preço.
            </span>
       </p>
          <button onClick={nextStage} className='box-exp-button-smart'>Ver detalhes</button>
        </>
      )}

      {stage === 2 && (
        <>
          <span className='box-exp-subtitle'>Por que escolher o Smart?</span>
          <div className='advantages-main-container'>
            <ul className='advantages-sub-container'>
              <li className='box-exp-advantages-title'>&bull; Benefício Exclusivo:</li>
              <span className='box-exp-advantages-smart'>
              A cada crédito adquirido, você ganha o direito a realizar um procedimento de limpeza dental, essencial para manter a saúde e a estética do seu sorriso.
              </span>
            </ul>
            <div className='advantages-sub-container'>
              <span className='box-exp-advantages-title'>&bull; Ortodontia de Alta Qualidade:</span>
              <span className='box-exp-advantages-smart'>
              Com o Pacote Smart, você tem acesso a um atendimento odontológico de excelência, com profissionais capacitados e tecnologia de ponta.              </span>
            </div>
                <div className='advantages-sub-container'>
                  <span className='box-exp-advantages-title'>&bull; Grande Retorno:</span> 
        <span className='box-exp-advantages-smart'>A opção ideal para quem deseja um tratamento eficiente e acessível, tornando-se o favorito de muitos pacientes.
        </span>
        </div>
          </div>
          <div className='box-exp-btn-stagio2-container'>
          <button onClick={prevStage} className='box-exp-button-smart-stagio2-voltar'>Voltar</button>
          <button onClick={nextStage} className='box-exp-button-smart-stagio2-valores'>Ver valores</button>
          </div>
      
        </>
      )}

      {stage === 3 && (
        <>
       <span className='box-exp-first-paragraf-smart'>Não deixe de cuidar do seu sorriso! Com o Pacote Smart, você investe na saúde bucal e garante aquele brilho especial que faz toda a diferença. Escolha ser inteligente em seus cuidados!

</span>
          <button onClick={prevStage} className='box-exp-button-smart'>Voltar</button>
        </>
        
      )}
    </div>
  );
}
