import React, { useState } from 'react';
import './css/explanation-boxes.css';

export default function VipBoxExp({ toggleExpand }) {
  const [stage, setStage] = useState(1); // Estado para controlar o estágio atual

  // Função para ir ao próximo estágio
  const nextStage = () => {
    if (stage < 3) setStage(stage + 1);
    if (stage === 2) toggleExpand();
  };

  // Função para voltar ao estágio anterior
  const prevStage = () => {
    if (stage > 1) setStage(stage - 1);
    if (stage === 3) toggleExpand();
  };

  // Renderização condicional com base no estágio
  return (
    <div className='box-explanation-main-container'>
      {stage === 1 && (
        <>
          <span className='box-exp-subtitle'>O Atendimento Exclusivo Que Você Merece!</span>
          <p className='box-exp-first-paragraf-vip'>
            <span>Seja VIP e descubra um novo padrão de cuidado odontológico.</span>
            <span>A escolha perfeita para quem busca um atendimento diferenciado.</span>
            <span>Máxima conveniência, proporcionando uma experiência exclusiva e sem igual. </span>
           

</p>
          <button onClick={nextStage} className='box-exp-button-vip'>Ver detalhes</button>
        </>
      )}

      {stage === 2 && (
        <>
          <span className='box-exp-subtitle'>Por que ser VIP?</span>
          <div className='advantages-main-container'>
            <ul className='advantages-sub-container'>
              <li className='box-exp-advantages-title'>&bull; Atendimento Prioritário:</li>
              <span className='box-exp-advantages-vip'>
              Diga adeus às longas esperas! Com o Pacote VIP, você desfruta de atendimento na fila exclusiva, garantindo que seu tempo seja valorizado e que você receba atenção imediata sempre que precisar.
              </span>
            </ul>
            <div className='advantages-sub-container'>
              <span className='box-exp-advantages-title'>&bull; Benefícios do Smart e Mais:</span>
              <span className='box-exp-advantages-vip'>
              Assim como no Pacote Smart, a cada crédito adquirido, você tem direito a realizar um procedimento de limpeza dental, além de todos os cuidados essenciais para a saúde do seu sorriso.
               </span>
            </div>
                <div className='advantages-sub-container'>
                  <span className='box-exp-advantages-title'>&bull; Reparos Inclusos:</span> 
        <span className='box-exp-advantages-vip'>Não se preocupe mais com gastos inesperados! Todos os reparos necessários no seu aparelho ortodontico—como soltar peças, fios ou desconfortos—estão inclusos no pacote, oferecendo tranquilidade e segurança a cada visita.
        </span>
        </div>
          </div>
          <div className='box-exp-btn-stagio2-container'>
          <button onClick={prevStage} className='box-exp-button-vip-stagio2-voltar'>Voltar</button>
          <button onClick={nextStage} className='box-exp-button-vip-stagio2-valores'>Ver valores</button>
          </div>
      
        </>
      )}

      {stage === 3 && (
        <>
       <span className='box-exp-first-paragraf-vip'>O Pacote VIP é mais do que um serviço; é uma experiência premium de cuidado odontológico que prioriza a sua saúde e conforto. Não deixe para depois: escolha o VIP e transforme sua jornada odontológica em algo verdadeiramente excepcional!
</span>
          <button onClick={prevStage} className='box-exp-button-vip'>Voltar</button>
        </>
        
      )}
    </div>
  );
}
