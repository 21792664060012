import React from 'react';
import './css/info-values.css';

export default function SmartInfoValues({ productName }) {
  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Smart 1' && (
        <ul className='product-itens-list'>
          <li>&bull; 1 crédito para manutenção de aparelho convencional.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; 1 crédito para limpeza dentária(profilaxia).</li>
        </ul>
      )}
      {productName === 'Smart 3' && (
        <ul className='product-itens-list'>
          <li>&bull; 3 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 110,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; 3 créditos para limpeza dentária(profilaxia).</li>
        </ul>
      )}
      {productName === 'Smart 6' && (
        <ul className='product-itens-list'>
          <li>&bull; 6 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 100,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; 6 créditos para limpeza dentária(profilaxia).</li>
          <li>&bull; 1 crédito para restauração dentária (obturação).</li>
        </ul>
      )}
      {productName === 'Smart 12' && (
        <ul className='product-itens-list'>
          <li>&bull; 12 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 90,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; 12 créditos para limpeza dentária(profilaxia).</li>
          <li>&bull; 2 créditos para restauração dentária (obturação).</li>
          <li>&bull; 1 voucher de 30% de desconto para 1 extração.</li>
        </ul>
      )}
      <button className='button-info-values'>ADQUIRIR!</button>
    </div>
  );
}
