import React, { useEffect, useState } from 'react';
import axios from 'axios';

const DentistSelector = () => {
  const [dentists, setDentists] = useState([]);
  const [selectedDentists, setSelectedDentists] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedDentistId, setSelectedDentistId] = useState('');
  const [selectedConsultorio, setSelectedConsultorio] = useState('');
  const [consultorios, setConsultorios] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // Carregar dados ao montar o componente
  useEffect(() => {
    const loadData = async () => {
      const storedDentists = localStorage.getItem('selectedDentists');
      if (storedDentists) {
        setSelectedDentists(JSON.parse(storedDentists));
      } else {
        try {
          const response = await axios.get('http://localhost:3001/dentist-allocations');
          setSelectedDentists(response.data);
          localStorage.setItem('selectedDentists', JSON.stringify(response.data));
        } catch (error) {
          console.error('Erro ao carregar dados do backend:', error);
        }
      }

      try {
        const response = await axios.get('http://localhost:3001/dentists/simple');
        setDentists(response.data);
      } catch (error) {
        console.error('Erro ao buscar dentistas:', error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedDentists', JSON.stringify(selectedDentists));
  }, [selectedDentists]);

  const handleConsultorioChange = (e) => {
    setConsultorios(Number(e.target.value));
    setSelectedDentists([]);
    setSuccessMessage('');
    localStorage.removeItem('selectedDentists');
  };

  const handleSelectDentist = async () => {
    const selectedDentist = dentists.find((dentist) => dentist.id === Number(selectedDentistId));
    if (selectedDentist && selectedConsultorio) {
      const newAllocation = {
        dentist_id: selectedDentist.id,
        dentist_name: selectedDentist.name,
        room: Number(selectedConsultorio),
      };

      try {
        await axios.post('http://localhost:3001/dentist-allocations/create', newAllocation);
        setSelectedDentists((prev) => [...prev, newAllocation]);
        setDentists((prev) => prev.filter((dentist) => dentist.id !== Number(selectedDentistId)));
        setSuccessMessage(`O dentista ${selectedDentist.name} foi alocado ao consultório ${selectedConsultorio}.`);
        setSelectedDentistId('');
        setSelectedConsultorio('');
      } catch (error) {
        console.error('Erro ao alocar dentista:', error);
      }
    }
  };

  const handleRemoveDentist = async (dentistId) => {
    try {
      await axios.delete(`http://localhost:3001/dentist-allocations/delete/${dentistId}`);
      const removedDentist = selectedDentists.find((dentist) => dentist.dentist_id === dentistId);
      setSelectedDentists((prev) => prev.filter((dentist) => dentist.dentist_id !== dentistId));
      setDentists((prev) => [
        ...prev,
        { id: removedDentist.dentist_id, name: removedDentist.dentist_name },
      ]);
      setSuccessMessage(`O dentista ${removedDentist.dentist_name} foi removido.`);
    } catch (error) {
      console.error('Erro ao remover dentista:', error);
    }
  };

  const handleResetSelection = () => {
    setSelectedDentists([]);
    setConsultorios(0);
    localStorage.removeItem('selectedDentists');
    setSuccessMessage('Seleção reiniciada.');
    setShowModal(false);
  };

  return (
    <div>
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

      {!consultorios && (
        <div>
          <h3>Selecione a quantidade de consultórios:</h3>
          <select onChange={handleConsultorioChange}>
            <option value="">Selecione</option>
            {[...Array(10).keys()].map((n) => (
              <option key={n + 1} value={n + 1}>
                {n + 1}
              </option>
            ))}
          </select>
        </div>
      )}

      {consultorios > 0 && (
        <div>
          <h3>Selecione o dentista e o consultório:</h3>
          <select
            value={selectedDentistId}
            onChange={(e) => setSelectedDentistId(e.target.value)}
          >
            <option value="">Selecione um dentista</option>
            {dentists.map((dentist) => (
              <option key={dentist.id} value={dentist.id}>
                {dentist.name}
              </option>
            ))}
          </select>

          <select
            value={selectedConsultorio}
            onChange={(e) => setSelectedConsultorio(e.target.value)}
          >
            <option value="">Selecione um consultório</option>
            {[...Array(consultorios).keys()].map((n) => (
              <option key={n + 1} value={n + 1}>
                Consultório {n + 1}
              </option>
            ))}
          </select>

          <button
            onClick={handleSelectDentist}
            disabled={!selectedDentistId || !selectedConsultorio}
          >
            Alocar
          </button>
        </div>
      )}

      {selectedDentists.length > 0 && (
        <div>
          <h3>Dentistas e Consultórios Alocados:</h3>
          <ul>
            {selectedDentists.map((dentist) => (
              <li key={dentist.dentist_id}>
                {dentist.dentist_name} - Consultório {dentist.room}
                <button
                  onClick={() => handleRemoveDentist(dentist.dentist_id)}
                  style={{ marginLeft: '10px', color: 'red' }}
                >
                  Remover
                </button>
              </li>
            ))}
          </ul>
          <button
            onClick={() => setShowModal(true)}
            style={{ color: 'orange' }}
          >
            Editar / Reiniciar Seleção
          </button>
        </div>
      )}

      {showModal && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
          }}
        >
          <h4>Tem certeza que deseja reiniciar a seleção?</h4>
          <button onClick={handleResetSelection} style={{ color: 'red', marginRight: '10px' }}>
            Sim
          </button>
          <button onClick={() => setShowModal(false)} style={{ color: 'green' }}>
            Não
          </button>
        </div>
      )}
    </div>
  );
};

export default DentistSelector;
