import * as Yup from 'yup';
import validateCPF from './validateCPF';
import moment from 'moment';

// Esquema de validação usando Yup
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Nome deve ter no mínimo 3 caracteres')
    .max(64, 'Nome deve ter no máximo 64 caracteres')
    .matches(/^[A-Za-zÀ-ÿ\s]+$/, 'Nome deve conter apenas letras')
    .required('Nome é obrigatório'),
    cpf: Yup.string()
    .test('cpf-numbers', 'Digite apenas números', value => /^\d*$/.test(value))
    .test('cpf-length', 'Deve conter 11 dígitos', value => value && value.length === 11)
    .test('cpf', 'CPF inválido', value => validateCPF(value))
    .required('CPF é obrigatório'),
  email: Yup.string()
    .email('Email inválido')
    .required('Email é obrigatório'),
    password: Yup.string()
    .min(8, <span>A senha deve ter no mínimo 8 caracteres</span>)
    .matches(/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/, 'A senha deve conter letras e números')
    .required('Campo obrigatório'),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem corresponder')
    .required('Confirmação de senha é obrigatória'),
    phone1: Yup.string()
    .test('Valid Brazilian phone number', 'Número de telefone inválido', value => {
       return /^\(\d{2}\)9\s\d{4}-\d{4}$/.test(value) && /^[\d\s()-]+$/.test(value);
     })
    .required('Telefone é obrigatório'),
confirmPhone: Yup.string()
    .test('Valid Brazilian phone number', 'Número de telefone inválido', value => {
       return /^\(\d{2}\)9\s\d{4}-\d{4}$/.test(value) && /^[\d\s()-]+$/.test(value);
     })
    .oneOf([Yup.ref('phone1'), null], 'Os números devem corresponder')
    .required('Confirmação de telefone é obrigatório'),
    age: Yup.string()
    .required('Data de nascimento é obrigatória')
    .test('DOB', 'Data de nascimento inválida', value => {
      if (!value) return false;
      
      const parts = value.split('/');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);
      
      // Verifica se o dia é válido
      if (day <= 0 || day > 31) return false;
      
      // Verifica se o mês é válido
      if (month <= 0 || month > 12) return false;
      
      // Verifica se o ano é válido
      if (year < 1905 || year > moment().year() - 6) return false;
      
      // Verifica se a data é válida e não é futura
      const date = moment(value, 'DD/MM/YYYY');
      if (!date.isValid() || date.isAfter(moment())) return false;
      
      return true;
    }),

    // phone2: Yup.string()
    // .matches(/^\d{9}$/, 'Telefone deve ter exatamente 9 números')
    
});

export default validationSchema