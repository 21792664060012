import React, { useState } from 'react';
import './css/explanation-boxes.css';

export default function StandardBoxExp({ toggleExpand }) {
  const [stage, setStage] = useState(1); // Estado para controlar o estágio atual

  // Função para ir ao próximo estágio
  const nextStage = () => {
    if (stage < 3) setStage(stage + 1);
    if (stage === 2) toggleExpand();
  };

  // Função para voltar ao estágio anterior
  const prevStage = () => {
    if (stage > 1) setStage(stage - 1);
    if (stage === 3) toggleExpand();
  };

  // Renderização condicional com base no estágio
  return (
    <div className='box-explanation-main-container'>
      {stage === 1 && (
        <>
          <span className='box-exp-subtitle'>O Começo do Seu Sorriso Perfeito!</span>
          <p className='box-exp-first-paragraf'>
            <span>Experimente a confiança de um sorriso alinhado.</span>
            <span>Atendimento odontológico essencial para a manutenção do seu aparelho ortodôntico.</span>
          </p>
          <button onClick={nextStage} className='box-exp-button'>Ver detalhes</button>
        </>
      )}

      {stage === 2 && (
        <>
          <span className='box-exp-subtitle'>Por que escolher o Standard?</span>
          <div className='advantages-main-container'>
            <ul className='advantages-sub-container'>
              <li className='box-exp-advantages-title'>&bull; Atendimento Especializado:</li>
              <span className='box-exp-advantages'>
                Nossos profissionais estão prontos para cuidar do seu sorriso com atenção e carinho.
              </span>
            </ul>
            <div className='advantages-sub-container'>
              <span className='box-exp-advantages-title'>&bull; Acessibilidade:</span>
              <span className='box-exp-advantages'>
                Ideal para quem busca um serviço de qualidade a um preço acessível.
              </span>
            </div>
                <div className='advantages-sub-container'>
                  <span className='box-exp-advantages-title'>&bull; Facilidade de Agendamento:</span> 
        <span className='box-exp-advantages'>Tenha a flexibilidade de agendar suas consultas de acordo com a sua rotina.</span>
        </div>
          </div>
          <div className='box-exp-btn-stagio2-container'>
          <button onClick={prevStage} className='box-exp-button-stagio2-voltar'>Voltar</button>
          <button onClick={nextStage} className='box-exp-button-stagio2-valores'>Ver valores</button>
          </div>
      
        </>
      )}

      {stage === 3 && (
        <>
       <span className='box-exp-first-paragraf'>Dê o primeiro passo para um sorriso mais bonito e saudável. Adquira seu Pacote Standard e comece sua jornada ortodôntica hoje mesmo!</span>
          <button onClick={prevStage} className='box-exp-button'>Voltar</button>
        </>
        
      )}
    </div>
  );
}
