import React, { useState } from 'react';
import axios from 'axios';
import RegisterForm from './RegisterForm';
import { useLocation, useNavigate } from 'react-router-dom';
import './css/register.css'
import RegisterPatientsList from './RegisterPatientsList';


const Register = () => {
  const navigate = useNavigate();
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showPatientsList, setShowPatientsList] = useState(false);
  const [patientsList, setPatientsList] = useState([]);
  const [orthoPlanId, setOrthoPlanId] = useState(1);
  const [selectedOpt, setselectedOpt] = useState(null)

  // orthoPlanId refere-se aos planos escolhidos , segue tabela:
  //| plano | valor de id 
  //  NENHUM|      1
  //  BASICO|      2
  //  SMART |      3
  //   VIP  |      4

  const [patientFormData, setPatientFormData] = useState({
    name: '',
    age: '',
    cpf: '',
    rg: '',
    email: '',
    password:'',
    phone1: '',
    phone2: '',
    planCardCode: '',
    planName: '',
    clinic_id: 1,
 
  });

  const [addressFormData, setAddressFormData] = useState({
    postal_code: '',
    street: '',
    city: '',
    country: '',
    address_number: '',
  });



  const handlePatientChange = (e) => {
    const { name, value } = e.target;
    setPatientFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleCheckboxChange = (e) => {
    const { name } = e.target;
     
    if (name === 'basic') {setOrthoPlanId(2); setselectedOpt(name); } 
    if (name === 'smart') {setOrthoPlanId(3); setselectedOpt(name);}
    if (name === 'vip') {setOrthoPlanId(4); setselectedOpt(name);}
   
  };


  const location = useLocation(); 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (location.pathname === '/cad-patient') {
        await axios.post('http://localhost:3001/patients/createByUser', {
          createPatientUserDto: {
            ...patientFormData,
            ortho: orthoPlanId > 1 ? true : false,
            vip: orthoPlanId === 4 ? true : false,
            age:Number(patientFormData.age),
            orthoPlanId:orthoPlanId,
            
          },
          createAddressDto: {...addressFormData,
            address_number:Number(addressFormData.address_number)
          },
        });
        alert('Seu cadastro foi efetuado com sucesso!');
      }

        await axios.post('http://localhost:3001/patients/create', {
          createPatientDto: {
            ...patientFormData,
            ortho: orthoPlanId > 1 ? true : false,
            vip: orthoPlanId === 4 ? true : false,
            age:Number(patientFormData.age),
            orthoPlanId:orthoPlanId
            
          },
          createAddressDto: {...addressFormData,
            address_number:Number(addressFormData.address_number)
          },
        });
        

      alert('Paciente cadastrado com sucesso!');
      // Limpar os formulários após o cadastro bem-sucedido
      setPatientFormData({
        name:'',
        age: '',
        cpf: '',
        rg: '',
        email: '',
        password:'',
        phone1: '',
        phone2: '',
        planCardCode: '',
        planName: '',
       
      });
      setAddressFormData({
        postal_code: '',
        street: '',
        city: '',
        country: '',
        address_number: '',
      });
      // setOrthoChecked(false);
      // setVipChecked(false);
    } catch (error) {
      console.error('Erro ao cadastrar paciente:', error);
      alert('Erro ao cadastrar paciente. Por favor, tente novamente.');
    }
  };

  const handleClick = () => {
    navigate('/adm-odk-frtx22wq'); // Redirects to the desired route
  };

  const showList = () => {
    setShowPatientsList(!showPatientsList)
  }

  const handleSearch = async () => {
    try {
      const response = await axios.get('http://localhost:3001/patients/findAll');
      setPatientsList(response.data);
      showList()
     
       // Salva os pacientes no estado
       console.log(response.data);
    } catch (error) {
      console.error('Erro ao buscar pacientes:', error);
    }

  };

  return (
    <div className='register'>
      <div className='cadastro-paciente-div'>
      <span className='cadastro-paciente'>Cadastro de Paciente</span>
      </div>
      <div className='cad-btns-container'>
      <button className='adm-btns' onClick={handleClick}>Voltar</button>
      <button className='adm-btns' onClick={() => setShowRegisterForm(!showRegisterForm)}>Ficha de Cadastro</button> 
      <button className='adm-btns' onClick={handleSearch}>Buscar Paciente</button>   
      </div>
      {showRegisterForm && <RegisterForm handleSubmit={handleSubmit}
      patientFormData={patientFormData}
      addressFormData={addressFormData}
      // orthoChecked={orthoChecked}
      // vipChecked={vipChecked}
      selectedOpt={selectedOpt}
      handlePatientChange={handlePatientChange}
      handleAddressChange={handleAddressChange}
      handleCheckboxChange={handleCheckboxChange}/>}
      {showPatientsList && <RegisterPatientsList patientsList={patientsList} />}

   
    </div>
  );
};

export default Register;
