import * as Yup from 'yup';
import validateCPF from './validateCPF';

const validationSchemaLogin = Yup.object().shape({
  doc: Yup.string()
    .test('doc', <span>Deve ser um email ou CPF válido</span>, function (value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value) || validateCPF(value);
    })
    .required('Campo obrigatório'),
    password: Yup.string()
    .min(8, <span>A senha deve ter no mínimo 8 caracteres</span>)
    .matches(/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/, 'A senha deve conter letras e números')
    .required('Campo obrigatório'),
  });
  

export default validationSchemaLogin;