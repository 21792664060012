import React from 'react';
import './css/info-values.css';

export default function SpecialInfoValues({ productName }) {
  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Special 1' && (
        <ul style={{color: 'rgba(25, 24, 24, 0.873)'}} className='product-itens-list'>
          <li>&bull; 1 crédito para manutenção de aparelho estético ou autoligado.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP)</li>
          <li>&bull; 1 crédito para limpeza dentária (profilaxia).</li>
        </ul>
      )}
      {productName === 'Special 3' && (
        <ul style={{color: 'rgba(25, 24, 24, 0.873)'}} className='product-itens-list'>
          <li>&bull; 3 créditos para manutenção de aparelho estético ou autoligado.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP)</li>
          <li>&bull; 3 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 1 crédito para restauração dentária (obturação).</li>
        </ul>
      )}
      {productName === 'Special 6' && (
        <ul style={{color: 'rgba(25, 24, 24, 0.873)'}} className='product-itens-list'>
          <li>&bull; 6 créditos para manutenção de aparelho estético ou autoligado.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP)</li>
          <li>&bull; 6 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 2 créditos para restauração dentária (obturação).</li>
          <li>&bull; 1 voucher de 30% de desconto para 1 extração.</li>
        </ul>
      )}
      {productName === 'Special 12' && (
        <ul style={{color: 'rgba(25, 24, 24, 0.873)'}} className='product-itens-list'>
          <li>&bull; 12 créditos para manutenção de aparelho estético ou autoligado.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; Prioridade na fila de espera (fila VIP)</li>
          <li>&bull; 12 créditos para limpeza dentária (profilaxia).</li>
          <li>&bull; 3 créditos para restauração dentária (obturação).</li>
          <li>&bull; 1 crédito para tratamento de canal.</li>
          <li>&bull; 1 kit de clareamento caseiro (plaquinha + gel).</li>
          <li>&bull; 1 kit de higiene bucal para aparelhos.</li>
          <li>&bull; 2 vouchers de 30% de desconto, para 1 extração cada.</li>
        </ul>
      )}

      {productName === 'Instalacao' && (
        <ul style={{color: 'rgba(25, 24, 24, 0.873)'}} className='product-itens-list'>
          <li>&bull; Instalaçao de aparelho estético ou autoligado.</li>
          <li>&bull; 1 crédito para manutenção de aparelho estético ou autoligado.</li>
        </ul>
      )}
      <button className='button-info-values'>ADQUIRIR!</button>
    </div>
  );
}
