const formatName = (fullName) => {
  const nameParts = fullName.split(' ').map(part => 
    part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  );

  if (nameParts.length > 2) {
    // Se o nome tiver mais de duas palavras, retorna o formato esperado
    return `${nameParts[0]} ${nameParts[1]} ${nameParts[nameParts.length - 1]}`;
  } else if (nameParts.length === 2) {
    // Se tiver exatamente duas palavras, retorna apenas as duas
    return `${nameParts[0]} ${nameParts[1]}`;
  }

  // Caso tenha apenas uma palavra, retorna ela formatada
  return nameParts[0];
};

export default formatName;
