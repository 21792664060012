import React, { useState } from 'react';
import './css/productsCardMob.css';
import SpecialInfoValues from './prodctsInfosValues/SpecialInfoValues';

export default function SpecialContainer({ special }) {
  // Estado que controla quais produtos estão expandidos
  const [expandedItems, setExpandedItems] = useState({});

  // Função para alternar o estado de um produto específico
  const toggleExpand = (item) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [item]: !prevState[item] // Alterna o estado de exibição
    }));
  };

  return (
    <div className='products-card-sub-container'>

    <div className='product-infos-container-special' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span className='special-font-color' >INSTALAÇÃO</span>
        <span  style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {special.specialIn},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('specialInstala')} className='special-font-color' style={{ fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.specialInstala && <SpecialInfoValues productName="Instalacao" />}
      </div>


      <div className='product-infos-container-special' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span className='special-font-color'>SPECIAL ONE</span>
        <span  style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {special.special1},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('specialOne')} className='special-font-color' style={{fontSize:'1.25rem'} }>DETALHES</span>
        </div>
        {expandedItems.specialOne && <SpecialInfoValues productName="Special 1" />}
      </div>

      <div className='product-infos-container-special' >
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span className='special-font-color' >SPECIAL TRI</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {special.special3},00 no Pix </span>
        </div>
        <span onClick={() => toggleExpand('specialTri')} className='special-font-color' style={{ fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.specialTri && <SpecialInfoValues productName="Special 3" />}
      </div>

      <div className='product-infos-container-special'>
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span className='special-font-color' >SPECIAL SIX</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {special.special6},00 no Pix</span>
        </div>
        <span onClick={() => toggleExpand('specialSix')} className='special-font-color' style={{fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.specialSix && <SpecialInfoValues productName="Special 6" />}
      </div>

      <div className='product-infos-container-special'>
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span className='special-font-color' style={{width:'50vw',textAlign:'left'}}>SPECIAL DOZEN</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {special.special12},00 no Pix</span>
        </div>
        <span onClick={() => toggleExpand('specialDozen')} className='special-font-color' style={{fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.specialDozen && <SpecialInfoValues productName="Special 12" />}
      </div>
    </div>
  );
}
