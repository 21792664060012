import React, { useEffect, useState } from 'react';
import './css/info-values.css'

export default function StandardInfoValues({ productName , handleCheckout}) {
  const [productInfo, setProductInfo] = useState({});

  // Função para processar a string productName em um objeto
  const processProductName = (productName) => {
    let quantity = 0;
    let refValue = '';
    
    // Processamento baseado no productName
    if (productName === 'Standard 1') {
      quantity = 1;
      refValue = 'standard1';
    } else if (productName === 'Standard 3') {
      quantity = 3;
      refValue = 'standard3';
    } else if (productName === 'Standard 6') {
      quantity = 6;
      refValue = 'standard6';
    } else if (productName === 'Standard 12') {
      quantity = 12;
      refValue = 'standard12';
    }

    // Define o objeto com base no productName processado
    const productInfo = {
      product: `Standard ${quantity === 1 ? 'One' : quantity === 3 ? 'Tri' : quantity === 6 ? 'Six' : 'Dozen'}`,
      quantity,
      refValue,
      refId: 'standard',
      description:'Crédito para Manutenção de aparelho'
    };

    // Atualiza o estado com o objeto processado
    setProductInfo(productInfo);
  };

  useEffect(() => {
    processProductName(productName);
  }, [productName]);

  // Renderização condicional baseada no nome do produto passado por props
  return (
    <div className='product-details'>
      {productName === 'Standard 1' && (
        <ul className='product-itens-list'>
          <li>&bull; 1 crédito para manutenção de aparelho convencional.</li>
        </ul>
      )}
      {productName === 'Standard 3' && (
        <ul className='product-itens-list'>
          <li>&bull; 3 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 80,00 no Pix.</li>
        </ul>
      )}
      {productName === 'Standard 6' && (
        <ul className='product-itens-list'>
          <li>&bull; 6 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 70,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; 1 crédito para limpeza dentária(profilaxia).</li>
          
        </ul>
      )}
      {productName === 'Standard 12' && (
        <ul className='product-itens-list'>
          <li>&bull; 12 créditos para manutenção de aparelho convencional.</li>
          <li className='uni-credit-value'>Cada crédito sai por R$ 60,00 no Pix.</li>
          <p>Benefícios desbloqueados:</p>
          <li>&bull; 2 créditos para limpeza dentária(profilaxia).</li>
          <li>&bull; 1 crédito para restauração dentária(obturação).</li>
          <li>&bull; 1 voucher de 30% de desconto para 1 extração.</li>
        </ul>
      )}
      <button onClick={()=> handleCheckout(productInfo)} className='button-info-values'>ADQUIRIR!</button>
    </div>
  );
}
