import React from 'react'
import './css/registerbyPatCover.css'

export default function RegisterByPatCover() {
  return (
    <div className='cover-main-ort'>

      <div className='auth-cover-text-box-ort'>
        <span className='revolution-ort'>
          
          Junte-se à revolução do atendimento em  
        </span> 

        <span className="ortodontia-text-cover">
          ortodontia
        </span>

        <span className='cadastre-descubra'> 
          Cadastre-se e descubra o futuro do seu sorriso!
        </span>
       
      </div>
    
    </div>
  )
}
