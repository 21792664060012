import React, { useState } from 'react';
import './css/registerSearch.css'
import axios from 'axios';

const RegisterPatientsList = ({ patientsList }) => {
  const [searchName, setSearchName] = useState('');
  const [searchDoc, setSearchDoc] = useState('');
  const [editMode, setEditMode] = useState(false)

  const [editedPatient, setEditedPatient] = useState(null); // Estado para armazenar os dados do paciente sendo editado

  const [cardId, setCardId ] = useState(null);

  const handlePlanInputChange = (e, index, field) => {
    const updatedPlans = [...editedPatient.patientPlans];
    updatedPlans[index][field] = e.target.value;
    setEditedPatient({
      ...editedPatient,
      patientPlans: updatedPlans
    });
  };
  

  const handleAddressInputChange = (e, index, field) => {
    const updatedAddresses = [...editedPatient.adresses];
    updatedAddresses[index][field] = e.target.value;
    setEditedPatient({
      ...editedPatient,
      adresses: updatedAddresses
    });
  };
  

  const handleEditModeToggle = async (patient) => {
    if (editMode && editedPatient && editedPatient.id === patient.id) {
      // Salvar as informações editadas
      // Implemente a lógica para atualizar as informações do paciente
      console.log('Salvar informações:', editedPatient);
      await handleSubmit()
    } else {
      setEditedPatient(patient);
    }
    setEditMode(!editMode);
  };

  const handleInputChange = (e, field) => {
    setEditedPatient({
      ...editedPatient,
      [field]: e.target.value
    });
  };

  const handleSubmit = async () => {
    
    try {
     await axios.patch('http://localhost:3001/patients/update', {
          updatePatientDto: {
            id:editedPatient.id,
            name: editedPatient.name,
            age: Number(editedPatient.age),
            cpf: editedPatient.cpf,
            rg: editedPatient.rg,
            email: editedPatient.email,
            phone1: editedPatient.phone1,
            phone2: editedPatient.phone2,
            ortho: editedPatient.ortho === 'Sim' ? true : false,
            vip: editedPatient.vip === 'Sim'? true : false,
          },
          
          updateAddressDto: { 
            id:editedPatient.adresses[0].id,
            postal_code: editedPatient.adresses[0].postal_code,
            street: editedPatient.adresses[0].street,
            city: editedPatient.adresses[0].city,
            country: editedPatient.adresses[0].country,
            address_number: editedPatient.adresses[0].address_number,
          },
        });
       
      alert('Paciente cadastrado com sucesso!');
      // Limpar os formulários após o cadastro bem-sucedido
    } catch (error) {
      console.error('Erro ao cadastrar paciente:', error);
      alert('Erro ao cadastrar paciente. Por favor, tente novamente.');
    }
  };
  
  const filteredPatients = patientsList.filter(patient => {
    const nameMatch = patient.name.toLowerCase().includes(searchName.toLowerCase());
    const docMatch = patient.cpf.includes(searchDoc) || patient.rg.includes(searchDoc);
    return nameMatch && docMatch;
  });

  return (
    <div className='search-patients-container'>
      <span className='busca-pacientes-title'>Busca de Pacientes</span>
      <div className='input-search-container'>
        <input className='search-form-input'
          type="text"
          placeholder="Buscar por nome"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <input 
        className='search-form-input'
          type="text"
          placeholder="Buscar por CPF ou RG"
          value={searchDoc}
          onChange={(e) => setSearchDoc(e.target.value)}
        />
      </div>
      {searchName || searchDoc ? (
        <ul>
          {filteredPatients.length > 0 ? (
            filteredPatients.map(patient => (
              <li className='search-register-card' key={patient.id}>
                <div className='sub-title-div'>
                  <span className='sub-title-text' >Paciente</span>
                </div>
                <div className='patient-personal-box'>
                  <div className='personal-sub-box'>

                  <div className='search-li-container'>
                  <span className='sub-box-tag'>Nome:</span> 

                  {/* <span className='sub-box-tag-text'>{patient.name}</span> */}

                  {editMode && patient.id === cardId ? (
                        <input
                          type="text"
                          className='sub-box-tag-text'
                          value={editedPatient.name}
                          onChange={(e) => handleInputChange(e, 'name')}
                        />
                      ) : (
                        <span className='sub-box-tag-text'>{editedPatient !== null && editedPatient.id === patient.id ? editedPatient.name : patient.name }</span>
                      )}
                  </div>
{/* 
                  <div className='search-li-container'>
                  <span className='sub-box-tag'>Sobrenome:</span> <span className='sub-box-tag-text'>{patient.name}</span>
                  </div> */}

                  <div className='search-li-container'>
                  <span className='sub-box-tag' >Idade:</span>
                  {editMode && patient.id === cardId ? (
                        <input
                          type="text"
                          className='sub-box-tag-text'
                          value={editedPatient.age}
                          onChange={(e) => handleInputChange(e, 'age')}
                          // Implemente a lógica para atualizar o estado do paciente com a nova idade aqui
                        />
                      ) : (
                        <span className='sub-box-tag-text'>{editedPatient !== null && editedPatient.id === patient.id  ? editedPatient.age : patient.age || 's/info'}</span>
                      )}
                  
                  </div>
                    
                  </div>

                  <div className='personal-sub-box'>

                  <div className='search-li-container'>
                  <span className='sub-box-tag'>CPF:</span>
                  {editMode && patient.id === cardId ? (
                        <input
                          type="text"
                          className='sub-box-tag-text'
                          value={editedPatient.cpf}
                          onChange={(e) => handleInputChange(e, 'cpf')}
                          // Implemente a lógica para atualizar o estado do paciente com o novo CPF aqui
                        />
                      ) : (
                        <span className='sub-box-tag-text' >{editedPatient !== null && editedPatient.id === patient.id ? editedPatient.cpf : patient.cpf || 's/info'}</span>
                      )} 
                  {/* <span className='sub-box-tag-text' >{patient.cpf || 's/info'}</span> */}
                  </div>
                    
                  <div className='search-li-container'>
                  <span className='sub-box-tag' >RG:</span>
                  {editMode && patient.id === cardId ? (
                        <input
                          type="text"
                          className='sub-box-tag-text'
                          value={editedPatient.rg}
                          onChange={(e) => handleInputChange(e, 'rg')}
                          // Implemente a lógica para atualizar o estado do paciente com o novo RG aqui
                        />
                      ) : (
                        <span className='sub-box-tag-text' >{editedPatient !== null && editedPatient.id === patient.id ? editedPatient.rg : patient.rg || 's/info'}</span>
                      )} 
                  {/* <span className='sub-box-tag-text' >{patient.rg || 's/info'}</span> */}
                  </div>

                  <div className='search-li-container'>
                  <span className='sub-box-tag' >codigo-pct:</span> <span className='sub-box-tag-text'>{patient.cod || 's/info'}</span>
                  </div>


                  </div>

                  <div className='personal-sub-box'>

                  <div className='search-li-container'>
                  <span className='sub-box-tag'>Orto:</span>
                  {editMode && patient.id === cardId ? (
                        <select
                          className='sub-box-tag-text'
                          value={editedPatient.ortho}
                          onChange={(e) => handleInputChange(e, 'ortho')}
                        >
                          <option value={patient.ortho}>selecione</option>
                          <option value='Sim'>Sim</option>
                          <option value='Não'>Não</option>
                        </select>
                      ) : (
                        <span className='sub-box-tag-text'>{editedPatient !== null && editedPatient.id === patient.id ? editedPatient.ortho : String(patient.ortho) === 'true' ? 'Sim' : 'Não'}</span>
                      )}
                   {/* <span className='sub-box-tag-text'>{patient.ortho ? 'Sim' : 'Não'}</span> */}
                  </div>
                  <div className='search-li-container'>
                  <span className='sub-box-tag'>VIP:</span> 
                  {editMode && patient.id === cardId ? (
                        <select
                          className='sub-box-tag-text'
                          value={editedPatient.vip}
                          onChange={(e) => handleInputChange(e, 'vip')}
                          // defaultValue={}
                        >
                          <option value={patient.vip}>selecione</option>
                          <option value='Sim'>Sim</option>
                          <option value='Não'>Não</option>
                        </select>
                      ) : (
                        <span className='sub-box-tag-text'>{editedPatient !== null && editedPatient.id === patient.id ? editedPatient.vip : String(patient.vip) === 'true' ? 'Sim' : 'Não'}</span>
                      )}
            
                  </div>

                  </div>


                  
                </div>
                
                <div className='sub-title-div'>
                  <span className='sub-title-text' >Contato</span>
                </div>
                <div className='patient-contact-box'>

                    <div className='patient-contact-sub-box'>

                        <div className='search-li-container'>
                        <span className='sub-box-tag'>Email:</span>
                        {editMode && patient.id === cardId ? (
                        <input
                          type="text"
                          className='sub-box-tag-text'
                          value={editedPatient.email}
                          onChange={(e) => handleInputChange(e, 'email')}
                          // Implemente a lógica para atualizar o estado do paciente com o novo RG aqui
                        />
                      ) : (
                          <span className='sub-box-tag-text'>{editedPatient !== null && editedPatient.id === patient.id ?  editedPatient.email : patient.email || 's/info'}</span> 
                          )}  
                        </div>

                    </div>

                    <div className='patient-contact-sub-box'>

                        <div className='search-li-container'>
                        <span className='sub-box-tag'>Telefone 1:</span>
                        {editMode && patient.id === cardId ? (
                        <input
                          type="text"
                          className='sub-box-tag-text'
                          value={editedPatient.phone1}
                          onChange={(e) => handleInputChange(e, 'phone1')}
                          // Implemente a lógica para atualizar o estado do paciente com o novo RG aqui
                        />
                      ) : (
                          <span className='sub-box-tag-text'>{editedPatient !== null && editedPatient.id === patient.id ? editedPatient.phone1 : patient.phone1|| 's/info'}</span> 
                          )}   
                    </div>

                    </div>

                    <div className='patient-contact-sub-box'>

                        <div className='search-li-container'>
                        <span className='sub-box-tag'>Telefone 2:</span>
                        {editMode && patient.id === cardId ? (
                        <input
                          type="text"
                          className='sub-box-tag-text'
                          value={editedPatient.phone2}
                          onChange={(e) => handleInputChange(e, 'phone2')}
                          // Implemente a lógica para atualizar o estado do paciente com o novo RG aqui
                        />
                      ) : (
                          <span className='sub-box-tag-text'>{editedPatient !== null && editedPatient.id === patient.id ? editedPatient.phone2 : patient.phone2|| 's/info'}</span> 
                          )}  
                      
                        </div>

                    </div>

                </div>
              <div className='sub-title-div'>
                <span className='sub-title-text'>Endereços</span> 
              </div>
              <div className='patient-address-box'>
  {patient.adresses && patient.adresses.length > 0 ? (
    <ul className='patient-address-box-sec'>
      {patient.adresses.map((address, index) => (
        <li className='address-sub-box' key={index}>
          <div className='search-li-container'>
            <span className='sub-box-tag'>País:</span>
            {editMode && patient.id === cardId ? (
              <input
                type="text"
                className='sub-box-tag-text'
                value={editedPatient.adresses[index].country || ""}
                onChange={(e) => handleAddressInputChange(e, index, 'country')}
              />
            ) : (
              <span className='sub-box-tag-text'>{address.country || 's/info'}</span>
            )}
          </div>
          <div className='search-li-container'>
            <span className='sub-box-tag'>Cidade:</span>
            {editMode && patient.id === cardId ? (
              <input
                type="text"
                className='sub-box-tag-text'
                value={editedPatient.adresses[index].city || ""}
                onChange={(e) => handleAddressInputChange(e, index, 'city')}
              />
            ) : (
              <span className='sub-box-tag-text'>{address.city || 's/info'}</span>
            )}
          </div>
          <div className='search-li-container'>
            <span className='sub-box-tag'>CEP:</span>
            {editMode && patient.id === cardId ? (
              <input
                type="text"
                className='sub-box-tag-text'
                value={editedPatient.adresses[index].postal_code || ""}
                onChange={(e) => handleAddressInputChange(e, index, 'postal_code')}
              />
            ) : (
              <span className='sub-box-tag-text'>{address.postal_code || 's/info'}</span>
            )}
          </div>
          <div className='search-li-container'>
            <span className='sub-box-tag'>Rua:</span>
            {editMode && patient.id === cardId ? (
              <input
                type="text"
                className='sub-box-tag-text'
                value={editedPatient.adresses[index].street || ""}
                onChange={(e) => handleAddressInputChange(e, index, 'street')}
              />
            ) : (
              <span className='sub-box-tag-text'>{address.street || 's/info'}</span>
            )}
          </div>
          <div className='search-li-container'>
            <span className='sub-box-tag'>Nº:</span>
            {editMode && patient.id === cardId ? (
              <input
                type="text"
                className='sub-box-tag-text'
                value={editedPatient.adresses[index].address_number || ""}
                onChange={(e) => handleAddressInputChange(e, index, 'address_number')}
              />
            ) : (
              <span className='sub-box-tag-text'>{address.address_number === 0 ? 's/info' : address.address_number}</span>
            )}
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <div className='sem-info'>
      <span>Nenhum endereço cadastrado.</span>
    </div>
  )}
</div>

                <div className='sub-title-div'>
                <span className='sub-title-text'>Planos</span> 
              </div>
                {/* <div className='patient-plan-box'>
                  
                {patient.patientPlans && patient.patientPlans.length >= 1 ? (
                  <ul className='patient-plan-box-sec'>
                    {patient.patientPlans.map((plan, index) => (
                      <li key={index}>
                        {plan.clinicPlan ?

                        <div className='plans-sub-box' >
                        <div className='search-li-container'>
                          <span className='sub-box-tag'>Plano:</span> <span className='sub-box-tag-text'>{plan.clinicPlan.name}</span>
                        </div>
                             <div className='search-li-container'>
                             <span className='sub-box-tag'>Código:</span> <span className='sub-box-tag-text'>{plan.plan_card_code}</span>
                           </div>
                        </div> :      <div className='sem-info'>
                    <span>Nenhum plano cadastrado.</span>
                  </div>}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className='sem-info'>
                    <span>Nenhum plano cadastrado.</span>
                  </div>
                )}
                </div> */}


<div className='patient-plan-box'>
  {patient.patientPlans && patient.patientPlans.length >= 1 ? (
    <ul className='patient-plan-box-sec'>
      {patient.patientPlans.map((plan, index) => (
        <li key={index}>
          {editMode && patient.id === cardId ? (
            <div className='plans-sub-box'>
              <div className='search-li-container'>
                <span className='sub-box-tag'>Plano:</span>
                <input
                  type="text"
                  className='sub-box-tag-text'
                  value={editedPatient.patientPlans[index].clinicPlan || ""}
                  onChange={(e) => handlePlanInputChange(e, index, 'clinicPlan')}
                />
              </div>
              <div className='search-li-container'>
                <span className='sub-box-tag'>Código:</span>
                <input
                  type="text"
                  className='sub-box-tag-text'
                  value={editedPatient.patientPlans[index].plan_card_code || ""}
                  onChange={(e) => handlePlanInputChange(e, index, 'plan_card_code')}
                />
              </div>
            </div>
          ) : (
            <div className='plans-sub-box'>
              <div className='search-li-container'>
                <span className='sub-box-tag'>Plano:</span>
                <span className='sub-box-tag-text'>{plan.clinicPlan || 's/info'}</span>
              </div>
              <div className='search-li-container'>
                <span className='sub-box-tag'>Código:</span>
                <span className='sub-box-tag-text'>{plan.plan_card_code || 's/info'}</span>
              </div>
            </div>
          )}
        </li>
      ))}
    </ul>
  ) : (
    <div className='sem-info'>
      <span>Nenhum plano cadastrado.</span>
    </div>
  )}
</div>





                <div className='edit-button-container'>
                  <button onClick={() => [setCardId(patient.id), handleEditModeToggle(patient) ]}>
                    {editMode && editedPatient && editedPatient.id === patient.id ? 'Salvar informações' : 'Editar Cadastro'}
                  </button>
                </div>

              </li>
            ))
          ) : (
            <p>Nenhum paciente encontrado</p>
          )}
        </ul>
      ) : null}
    </div>
  );
};

export default RegisterPatientsList;
