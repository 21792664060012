import React, { useState } from 'react';
import './css/productsCardMob.css';
import SmartInfoValues from './prodctsInfosValues/SmartInfoValues';

export default function SmartContainer({ smart }) {
  // Estado que controla quais produtos estão expandidos
  const [expandedItems, setExpandedItems] = useState({});

  // Função para alternar o estado de um produto específico
  const toggleExpand = (item) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [item]: !prevState[item] // Alterna o estado de exibição
    }));
  };

  return (
    <div className='products-card-sub-container'>
      <div className='product-infos-container-smart' onClick={() => toggleExpand('smartOne')}>
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>SMART ONE</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {smart.smart1},00 no Pix</span>
        </div>
        <span style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.smartOne && <SmartInfoValues productName="Smart 1" />}
      </div>

      <div className='product-infos-container-smart' onClick={() => toggleExpand('smartTri')}>
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>SMART TRI</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {smart.smart3},00 no Pix</span>
        </div>
        <span style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.smartTri && <SmartInfoValues productName="Smart 3" />}
      </div>

      <div className='product-infos-container-smart' onClick={() => toggleExpand('smartSix')}>
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span>SMART SIX</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {smart.smart6},00 no Pix</span>
        </div>
        <span style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.smartSix && <SmartInfoValues productName="Smart 6" />}
      </div>

      <div className='product-infos-container-smart' onClick={() => toggleExpand('smartDozen')}>
        <div className='product-infos-sub-container'>
        <div className='product-info-value'> 
        <span style={{width:'50vw',textAlign:'left'}}>SMART DOZEN</span>
        <span style={{color: 'rgb(22, 209, 43)', fontSize:'1.25rem'}}>R$ {smart.smart12},00 no Pix</span>
        </div>
        <span style={{color:'#f8f9fa', fontSize:'1.25rem'}}>DETALHES</span>
        </div>
        {expandedItems.smartDozen && <SmartInfoValues productName="Smart 12" />}
      </div>
    </div>
  );
}
