import React, { useState } from 'react';
import './css/queeEntryMob.css';
import './css/queeEntry.css';

function QueeEntry({ onAddPerson }) {
  const [documento, setDocumento] = useState('');
  const [category, setCategory] = useState(undefined); // Valor padrão para Manutenção
  const [cleaning, setCleaning] = useState(null); // Valor inicial para Limpeza
  const [repair, setRepair] = useState(undefined); // Valor padrão para Reparo

  const handleSubmit = (e) => {
    e.preventDefault();

    if (documento.length <= 11 && documento.length > 0) {
      // Chame a função de callback onAddPerson com os valores
      onAddPerson({
        doc: documento,
        category,
        cleaning,
        repair,
      });

      // Limpe os campos de entrada após o envio
      setDocumento('');
      setCategory(undefined);
      setCleaning(null);
      setRepair(undefined);
    } else {
      alert('O documento deve ter no máximo 11 caracteres');
    }
  };

  // Validação para desabilitar o botão
  const isButtonDisabled = 
  !documento || 
  ((category === undefined || category === null) && 
   (cleaning === null) && 
   (repair === undefined || repair === null));

  return (
    <form 
      className="quee-entry-form"
      onSubmit={handleSubmit}
    >
      <input
        className="input-doc"
        type="text"
        value={documento}
        onChange={(e) => setDocumento(e.target.value)}
        placeholder="Apenas números: RG ou CPF ou COD"
        maxLength={11}
      />
      <label>
        Manutenção:
        <select
          className="quee-entry-dropdown"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value={null}>Não</option>
          <option value="standard">M. Stand</option>
          <option value="smart">M. Smart</option>
          <option value="vip">M. Vip</option>
          <option value="special">M. Special</option>
        </select>
      </label>
      <div className="quee-entry-cleaning">
        <label>
          Limpeza:
          <input
            type="radio"
            name="cleaning"
            value="true"
            checked={cleaning === true}
            onChange={() => setCleaning(true)}
          />
          Sim
        </label>
        <label>
          <input
            type="radio"
            name="cleaning"
            value="false"
            checked={cleaning === false}
            onChange={() => setCleaning(false)}
          />
          Não
        </label>
      </div>
      <label>
        Reparo:
        <select
          className="quee-entry-dropdown"
          value={repair}
          onChange={(e) => setRepair(e.target.value)}
        >
          <option value={null}>Não</option>
          <option value="repair_min_credits">Rep. Min</option>
          <option value="repair_med_credits">Rep. Méd</option>
          <option value="repair_max_credits">Rep. Máx</option>
        </select>
      </label>
      <button 
        className="quee-entry-btn"
        type="submit"
        disabled={isButtonDisabled} // Desabilita o botão com base na validação
      >
        Entrar na Fila
      </button>
    </form>
  );
}

export default QueeEntry;
