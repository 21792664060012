import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function RegisterRedirectBtn() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/cad-odk-kt34hsba'); // Redirects to the desired route
  };

  return (
   
      <button className='adm-btns' onClick={handleClick}>Cadastrar Paciente</button>
   
  );
}
