import React, { useState } from 'react';
import './css/explanation-boxes.css';

export default function SpecialBoxExp({ toggleExpand }) {
  const [stage, setStage] = useState(1); // Estado para controlar o estágio atual

  // Função para ir ao próximo estágio
  const nextStage = () => {
    if (stage < 3) setStage(stage + 1);
    if (stage === 2) toggleExpand();
  };

  // Função para voltar ao estágio anterior
  const prevStage = () => {
    if (stage > 1) setStage(stage - 1);
    if (stage === 3) toggleExpand();
  };

  // Renderização condicional com base no estágio
  return (
    <div className='box-explanation-main-container'>
      {stage === 1 && (
        <>
          <span className='box-exp-subtitle'>A Elegância e Exclusividade Que Você Merece!</span>
          <p className='box-exp-first-paragraf-special'>
            <span>
            A escolha ideal para quem valoriza a estética e o cuidado superior.   
            </span>
            <span>
           Para você, que busca não apenas um sorriso saudável, mas também um tratamento que realce sua beleza.
            </span>
            <span>
            Pacote para atendimento à pacientes com indicação para aparelho Auto-Ligado ou Estético.
            </span>
            </p>
          <button onClick={nextStage} className='box-exp-button-special'>Ver detalhes</button>
        </>
      )}

      {stage === 2 && (
        <>
          <span className='box-exp-subtitle'>Por que ser special?</span>
          <div className='advantages-main-container'>
            <ul className='advantages-sub-container'>
              <li className='box-exp-advantages-title'>&bull; Atendimento Premium:</li>
              <span className='box-exp-advantages-special'>
              Como membro do Pacote Special, você tem acesso à fila VIP, garantindo um atendimento prioritário e sem esperas, perfeito para sua agenda sofisticada.
              </span>
            </ul>
            <div className='advantages-sub-container'>
              <span className='box-exp-advantages-title'>&bull; Aparelhos Estéticos e Autoligados:</span>
              <span className='box-exp-advantages-special'>
              Este pacote é especialmente desenvolvido para quem utiliza aparelhos estéticos e autoligados, oferecendo uma experiência de tratamento que combina eficácia e beleza.
               </span>
            </div>
                <div className='advantages-sub-container'>
                  <span className='box-exp-advantages-title'>&bull; Benefícios do Pacote Smart:</span> 
        <span className='box-exp-advantages-special'>Assim como os outros pacotes, cada crédito adquirido permite que você realize um procedimento de limpeza dental, cuidando do seu sorriso com atenção e carinho.
        </span>
        </div>
        <div className='advantages-sub-container'>
                  <span className='box-exp-advantages-title'>&bull; Reparos Inclusos:</span> 
        <span className='box-exp-advantages-special'>Tenha a tranquilidade de saber que alguns reparos no seu aparelho estão inclusos no pacote, proporcionando uma experiência sem preocupações e cheia de conforto.
        </span>
        </div>
          </div>
          <div className='box-exp-btn-stagio2-container'>
          <button onClick={prevStage} className='box-exp-button-special-stagio2-voltar'>Voltar</button>
          <button onClick={nextStage} className='box-exp-button-special-stagio2-valores'>Ver valores</button>
          </div>
      
        </>
      )}

      {stage === 3 && (
        <>
       <span className='box-exp-first-paragraf-special'>O Pacote Special é mais do que um serviço; é um estilo de vida que reflete sua personalidade e seu desejo por excelência. Transforme sua jornada odontológica em uma experiência de elegância e cuidado premium. Escolha o Pacote Special e viva a diferença!
</span>
          <button onClick={prevStage} className='box-exp-button-special'>Voltar</button>
        </>
        
      )}
    </div>
  );
}
