import React from 'react';
import axios from 'axios';
import '../queeAdm/css/queRemoverBtn.css'; // Estilo CSS para o botão

axios.defaults.baseURL = 'http://localhost:3001';

export default function QueeClearBtn() {
  const handleRemove = () => {
    // Exibir alerta de confirmação
    const confirmDelete = window.confirm('Tem certeza de que deseja limpar a fila ?');
    if (confirmDelete) {
      // Enviar solicitação de exclusão para a rota
      axios.delete('quee/clear')
        .then(response => {
          console.log('Fila limpa com sucesso:', response);
          // Aqui você pode atualizar o estado da sua aplicação ou tomar qualquer outra ação necessária após a remoção do paciente
        })
        .catch(error => {
          console.error('Erro ao limpar a fila:', error);
          // Aqui você pode lidar com o erro, exibir uma mensagem de erro ao usuário, etc.
        });
    }
  };

  return (
   
      <button className="adm-btns" onClick={handleRemove}>
        Limpar Fila
      </button>
    
  );
}
