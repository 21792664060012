import React from 'react';
import axios from 'axios';
import '../queeAdm/css/queRemoverBtn.css'; // Estilo CSS para o botão

axios.defaults.baseURL = 'http://localhost:3001';

export default function QueeRemoverBtn({ personId, noRemoverBtn }) {
  const handleRemove = () => {
    // Exibir alerta de confirmação
    const confirmDelete = window.confirm('Tem certeza de que deseja remover este paciente?');
    if (confirmDelete) {
      // Enviar solicitação de exclusão para a rota
      axios.delete('quee/remove-patient', { data: { id: personId } })
        .then(response => {
          console.log('Paciente removido com sucesso:', response);
          // Aqui você pode atualizar o estado da sua aplicação ou tomar qualquer outra ação necessária após a remoção do paciente
        })
        .catch(error => {
          console.error('Erro ao remover paciente:', error);
          // Aqui você pode lidar com o erro, exibir uma mensagem de erro ao usuário, etc.
        });
    }
  };

  return (
    (noRemoverBtn &&
      <button className="round-button" onClick={handleRemove}>
        X
      </button>
    )
  );
}
